import React from 'react';

interface typeCheckboxProps {
  checked: {id: string, value: boolean},
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>, id: string) => void,
}

const Checkbox = (props: typeCheckboxProps) => {
  const {checked, onChange} = props;
  return (
    <div className='form-check form-check d-flex align-items-center justify-content-center'>
      <input className='form-check-input float-none' type='checkbox'
        id={`checkbox-${checked.id}`}
        onChange={(e) => onChange(e, checked.id)}
        checked={checked.value}></input>
    </div>
  );
};

export default Checkbox;

import React from 'react';
import {loginRequest} from '../authConfig';
import {toast} from 'react-toastify';
import {useMsal} from '@azure/msal-react';


export const SignInButton = () => {
  const {instance} = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      toast.error(JSON.stringify(e));
    });
  };

  return (
    <button className='gz-btn-primary-violet' onClick={handleLogin}>
      Signin
    </button>
  );
};

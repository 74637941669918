import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {ReactComponent as GirlYogaIllustration} from './images/girl-yoga-illustration.svg';
import {PageLayout} from './components/PageLayout';
import React from 'react';
import Renamer from './renamer/Renamer';


const App = () => {
  return (
    <PageLayout>
      <div className='App'>
        <AuthenticatedTemplate>
          <Renamer/>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <div className='text-center'>
            <GirlYogaIllustration width={280} height={280}/>
            <div>Please sign-in with your Microsoft login</div>
          </div>
        </UnauthenticatedTemplate>
      </div>
    </PageLayout>
  );
};

export default App;


import Header from './Header';
import React from 'react';

interface typePageLayoutProps {
  children: React.ReactElement,
}

export const PageLayout = (props: typePageLayoutProps) => {
  return (
    <>
      <Header/>
      {props.children}
    </>
  );
};

import 'bootstrap/dist/css/bootstrap.css';
import './styles/common.scss';
import 'rc-slider/assets/index.css';
import App from './App';
import {MsalProvider} from '@azure/msal-react';
import {PublicClientApplication} from '@azure/msal-browser';
import React from 'react';
import {ToastContainer} from 'react-toastify';
import {createRoot} from 'react-dom/client';
import {injectStyle} from 'react-toastify/dist/inject-style';
import {msalConfig} from './authConfig';

/* import css is not working */
/* on demand inject toast style */
injectStyle();

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <MsalProvider instance={msalInstance}>
      <App />
      <ToastContainer
        position='bottom-left'
        draggable={false}
        autoClose={6000}
        closeOnClick={false}
      />
    </MsalProvider>,
);

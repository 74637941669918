import '../styles/tooltip.scss';
import React, {useRef, useState} from 'react';
import {Overlay} from 'react-bootstrap';

export interface tooltipProps {
    text: React.ReactNode,
    children: React.ReactNode,
    customClass?: string,
}

export default function Tooltip(props: tooltipProps) {
  const {text, children, customClass} = props;

  const [
    showMenu,
    setShowMenu,
  ] = useState<boolean>(false);
  const targetRef = useRef(null);

  const handleShowMenu = (value: boolean) => {
    setShowMenu(value);
  };

  return (
    <>
      <div
        ref={targetRef}
        onClick={() => handleShowMenu(true)}
        onMouseLeave={() => handleShowMenu(false)}
        className={customClass}
      >
        {children}
      </div>
      <Overlay
        target={targetRef.current}
        show={showMenu}
        rootClose={true}
        placement='top'
        rootCloseEvent='mousedown'
        onHide={() => handleShowMenu(false)}
      >
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div {...props}>
            <div className="tooltip-container">
              <span className="tooltip-text">{text}</span>
            </div>
          </div>
        )}
      </Overlay>
    </>
  );
}

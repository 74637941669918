import React from 'react';

interface typeRadioButtonProps {
  checked: {id: string, value: boolean},
  values: {id: string, label: string}[],
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>, id: string) => void,
}

const RadioButton = (props: typeRadioButtonProps) => {
  const {values, checked, onChange} = props;
  return (
    <>
      {values.map((item) => {
        return (
          <div className='form-check' key={item.id}>
            <input className='form-check-input' type='radio' name='radiobutton' id={`radiobutton-${item.id}`} onChange={(e) => onChange(e, item.id)}checked={item.id === checked.id ? checked.value : false}></input>
            <label className='form-check-label' htmlFor={`radiobutton-${item.id}`}>
              {item.label}
            </label>
          </div>
        );
      })
      }
    </>
  );
};

export default RadioButton;

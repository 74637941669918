

import {useEffect, useState} from 'react';
import {getEnvironmentVariable} from '../helper';
import {useMsal} from '@azure/msal-react';


const useAccessToken = () => {
  const {instance, accounts} = useMsal();
  const [
    accessToken, setAccessToken,
  ] = useState<string>('');
  const [
    expiresOn, setExpiresOn,
  ] = useState<Date | null>(null);

  useEffect(() => {
    instance
        .acquireTokenSilent({
          scopes: [
            getEnvironmentVariable('REACT_APP_AZURE_LOGIN_SCOPE'),
          ],
          account: accounts[0],
        }).then((response) => {
          setAccessToken(response.accessToken);
          setExpiresOn(response.expiresOn);
        }).catch((e) => {
          // instance.acquireTokenPopup({
          //   scopes: [
          //     getEnvironmentVariable('REACT_APP_AZURE_LOGIN_SCOPE'),
          //   ],
          //   account: accounts[0],
          // }).then((response) => {
          //   setAccessToken(response.accessToken);
          //   setExpiresOn(response.expiresOn);
          // });
        });
  }, [
    accounts, instance,
  ]);

  return {accessToken: accessToken, expiresOn: expiresOn};
};

export default useAccessToken;

import {FILE_NOT_SUPPORTED_ERROR, HELP_TEXT, MAX_UPLAOD_ERROR, MAX_UPLOAD_LIMIT, RADIO_BUTTON_VALUES, ROOT_FOLDER, ROOT_FOLDER_CLIENT, SUPPORTED_FILE_FORAMTS, SUPPORTED_FILE_TYPES, TERM_YEAR, ZIP_DOWNLOAD_FILE_NAME_PREFIX} from '../constants/constants';
import React, {useCallback, useEffect, useState} from 'react';
import {capitaliseFirstLetter, checkFileNameMatchesPattern, getFileFormat, toNormalizedLowerCase} from '../helper';
import {typeInMemoryFileAttribute, typeInMemoryFileData, typeInMemoryFileDataAttr, typeLobEntity, typeOptionList, typeTableEntity} from '../types/renamer';
import {AZURE_API_ENDPOINT} from '../constants/protectedResources';
import Checkbox from '../components/Checkbox';
import {ReactComponent as CsvFileIcon} from '../images/csv-file-icon.svg';
import {ReactComponent as DeleteIcon} from '../images/delete-icon.svg';
import {ReactComponent as GirlYogaIllustration} from '../images/girl-yoga-illustration.svg';
import {ReactComponent as InfoIcon} from '../images/info-icon.svg';
import {ReactComponent as PdfFileIcon} from '../images/pdf-file-icon-v3.svg';
import RadioButton from '../components/RadioButton';
import Select from 'react-select';
import Tooltip from '../components/Tooltip';
import {ReactComponent as XlsxFileIcon} from '../images/xlsx-file-icon-v2.svg';
import _ from 'lodash';
import {findSimilarWords} from '../helper/levenshtein';
import {saveAs} from 'file-saver';
import {toast} from 'react-toastify';
import useAccessToken from '../hooks/useAccessToken';


const currentDate = new Date();
const startYear = currentDate.getFullYear()+1;
const endYear = startYear - TERM_YEAR;


function Renamer() {
  const {accessToken} = useAccessToken();

  const [
    entities, setEntities,
  ] = useState<typeTableEntity[]>([
  ]);
  const [
    clientList, setClientList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    accountList, setAccountList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    carrierList, setCarrierList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    subAccountList, setSubAccountList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    lobList, setLobList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    policyYearList, setPolicyYearList,
  ] = useState<typeOptionList[]>([
  ]);
  const [
    fileData, setFileData,
  ] = useState<typeInMemoryFileData[]>([
  ]);
  const [
    lastFileId, setLastFileId,
  ] = useState<number>(0);
  const [
    selectedClientName, setSelectedClientName,
  ] = useState<typeOptionList>({value: '', label: ''});
  const [
    clientError, setClientError,
  ] = useState<boolean>(false);
  const [
    completedFilesCount, setCompletedFilesCount,
  ] = useState<number>(0);
  const [
    selectedRadio, setSelectedRadio,
  ] = useState<{id: string, value: boolean}>({
    id: 'dashboard',
    value: true,
  });
  const [
    maxUploadError, setMaxUploadError,
  ] = useState<string>('');
  const [
    selectAllFiles, setSelectAllFiles,
  ] = useState<boolean>(false);
  const [
    showDelete, setShowDelete,
  ] = useState<boolean>(false);
  const [
    selectedCount, setSelectedCount,
  ] = useState<number>(0);
  const [
    loading,
    setLoading,
  ] = useState(false);

  const getPolicyYear = useCallback((): typeOptionList[] => {
    const policyYear: typeOptionList[] = [
    ];
    for (let year = endYear; year <= startYear; year++) {
      policyYear.push({label: year.toString(), value: year.toString()});
    }
    return policyYear;
  }, [
  ]);

  useEffect(() => {
    const policyYear = getPolicyYear();
    setPolicyYearList(policyYear);
  }, [
    getPolicyYear,
  ]);

  /*
   * Create a list of objects with value, label for a given attribute
   */
  const createList = useCallback((parsedEntities: typeTableEntity[], attribute: 'partitionKey' | 'accountName' | 'carrierName' | 'subAccountName') => {
    const parsedList = parsedEntities.map((entity) => {
      return {value: entity[attribute], label: entity[attribute]};
    });

    return parsedList.filter((entity:any, index: number) =>
      parsedList.findIndex((item: any) =>
        item.value === entity.value) === index);
  }, [
  ]);

  /*
   * Fetch lob, preprocessing table
   */
  useEffect(() => {
    if (accessToken === '') return;
    const header = new Headers();
    header.append('Authorization', `Bearer ${accessToken}`);

    fetch(`${AZURE_API_ENDPOINT}preprocessing`, {method: 'GET', headers: header})
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
          throw new Error(response.statusText);
        })
        .then((result) => {
          const parsedEntities = JSON.parse(result);
          const tableData: typeTableEntity[] = parsedEntities.map(
              (entity: any) => {
                return {
                  partitionKey: entity['partition_key'],
                  rowKey: entity['row_key'],
                  accountName: entity['account_name'],
                  subAccountName: entity['sub_account'],
                  carrierName: entity['carrier_name'],
                  active: entity['active'],
                  client: entity['client'],
                } as typeTableEntity;
              });
          setEntities(tableData);
          const clientData = createList(tableData, 'partitionKey');
          setClientList(clientData);
          if (clientData.length === 1) {
            setSelectedClientName(clientData[0]);
            const filteredData = tableData.filter((entity) => {
              return entity.partitionKey === clientData[0].value;
            });
            setAccountList(createList(filteredData, 'accountName'));
            setCarrierList(createList(filteredData, 'carrierName'));
            setSubAccountList(createList(filteredData, 'subAccountName'));
          }
        })
        .catch((error: any) => {
          toast.error(error.toString());
        });

    fetch(`${AZURE_API_ENDPOINT}lob`, {method: 'GET', headers: header})
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
          throw new Error(response.statusText);
        })
        .then((result) => {
          const parsedEntities = JSON.parse(result);
          const tableData: typeLobEntity[] = parsedEntities.map(
              (entity: any) => {
                return {
                  partitionKey: entity['partition_key'],
                  rowKey: entity['row_key'],
                  lineOfBusiness: entity['lob'].toUpperCase(),
                } as typeLobEntity;
              });

          const parsedList = tableData.map((entity) => {
            return {
              value: entity.lineOfBusiness, label: entity.lineOfBusiness};
          });
          setLobList(parsedList);
        })
        .catch((error: any) => {
          toast.error(JSON.stringify(error.response));
        });
  }, [
    createList, accessToken,
  ]);

  /*
   * Get list of files uploaded into memory
   */
  const getFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files || [
    ]).forEach((file, index, arr) => {
      if (arr.length + fileData.length > MAX_UPLOAD_LIMIT) {
        setMaxUploadError(MAX_UPLAOD_ERROR);
        return;
      }
      if (!SUPPORTED_FILE_TYPES.includes(file.type)) {
        setMaxUploadError(FILE_NOT_SUPPORTED_ERROR);
        return;
      }
      const findex = fileData.findIndex((item) => {
        return item.originalFileName.toLowerCase() === file.name.toLowerCase();
      });
      const filePattern = checkFileNameMatchesPattern(file.name);
      const similarAccount = findSimilarWords(accountList.map((account) => account.value), filePattern ? filePattern.accountName : '');
      const similarCarrier = findSimilarWords(carrierList.map((carrier) => carrier.value), filePattern ? filePattern.carrierName : '', 5);

      const mangoFileName = filePattern ? `${startYear.toString().substring(0, 2)}${filePattern.year}${filePattern.month}-${selectedClientName.value}-${selectedRadio.id ==='expiration' && similarAccount[0] ? toNormalizedLowerCase(similarAccount[0].word) : '<account>'}-${similarCarrier[0] ? toNormalizedLowerCase(similarCarrier[0].word): '<carrier>'}-${toNormalizedLowerCase(filePattern.lob)}-${startYear.toString().substring(0, 2)}${filePattern.startYear}-${startYear.toString().substring(0, 2)}${filePattern.endYear}-${similarAccount[0] ? toNormalizedLowerCase(similarAccount[0].word) : '<subaccount>'}` : '';

      const clientFileName = filePattern ? `${similarAccount[0] ? similarAccount[0].word: '<account>'}-${filePattern.startYear}-${filePattern.endYear}-${filePattern.lob}-loss runs-${filePattern.carrierName}-VD-${filePattern.month}.${filePattern.day}.${filePattern.year}` : '';

      if (findex < 0) {
        const data: typeInMemoryFileData = {
          id: lastFileId+index,
          file: file,
          originalFileName: file.name,
          mangoFileName: mangoFileName,
          clientFileName: clientFileName,
          lineOfBusiness: filePattern ? filePattern.lob.toUpperCase() : '',
          effectiveYear: filePattern ? `${startYear.toString().substring(0, 2)}${filePattern.startYear}` : endYear.toString(),
          expiryYear: (filePattern ?
            `${startYear.toString().substring(0, 2)}${filePattern.endYear}` : startYear.toString()),
          accountName: filePattern && similarAccount[0] && selectedRadio.id ==='expiration' ? similarAccount[0].word : '',
          subAccountName: filePattern && similarAccount[0] ? similarAccount[0].word : '',
          valuedDate: filePattern ? `${startYear.toString().substring(0, 2)}${filePattern.year}-${filePattern.month}-${filePattern.day}` : '',
          carrierName: filePattern && similarCarrier[0] ? similarCarrier[0].word : '',
          fileUrl: '',
          checked: false,
          error: {
            lineOfBusiness: false,
            expiryYear: false,
            effectiveYear: false,
            subAccountName: false,
            valuedDate: false,
            carrierName: false,
            accountName: false,
          },
        };
        const calendar = document.getElementById(`calendar-${data.id}`) as HTMLInputElement;
        if (calendar) {
          calendar.valueAsDate = new Date(data.valuedDate);
        }
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
          data.fileUrl = e.target ? e.target.result as string: '';
          setFileData((prevState) => {
            return [
              ...prevState,
              {...data},
            ];
          });
        };
        fileReader.readAsDataURL(data.file);

        if (arr.length === index+1) {
          setLastFileId(lastFileId+arr.length);
        }
      }
    });
    e.target.value = '';
  };

  /*
   * Construct file name for Project Mango and Client
  */
  const constructFileName = (attribute: typeInMemoryFileAttribute, value: string, fileFormat: 'mango' | 'client', otherValues: typeInMemoryFileData) => {
    const date = otherValues.valuedDate.split('-');
    // Initialize mango file name object
    const fileNameObj = {
      accountName: otherValues.accountName === '' ? '<account>' : toNormalizedLowerCase(otherValues.accountName),
      valuedDate: otherValues.valuedDate === '' ? '<yyymm>' : `${date[0]+date[1]}`,
      subAccountName: otherValues.subAccountName === '' ? '<subaccount>' : toNormalizedLowerCase(otherValues.subAccountName),
      carrierName: otherValues.carrierName === '' ? '<carrier>' : toNormalizedLowerCase(otherValues.carrierName),
      effectiveYear: otherValues.effectiveYear === '' ?
      endYear : otherValues.effectiveYear,
      expiryYear: otherValues.expiryYear === '' ?
      startYear : otherValues.expiryYear,
      lineOfBusiness: otherValues.lineOfBusiness === '' ? '<lob>' : otherValues.lineOfBusiness.toLowerCase(),
    };

    // Initialize client name object
    const clientFileNameObj = {
      accountName: otherValues.accountName === '' ? '<account>' : otherValues.accountName,
      valuedDate: otherValues.valuedDate === '' ? '<valueddate>' : `${date[1]}.${date[2]}.${date[0]}`,
      subAccountName: otherValues.subAccountName === '' ? '<account>' : otherValues.subAccountName,
      carrierName: otherValues.carrierName === '' ? '<carrier>' : otherValues.carrierName,
      effectiveYear: otherValues.effectiveYear === '' ? endYear : otherValues.effectiveYear.substring(2, otherValues.effectiveYear.length),
      expiryYear: otherValues.expiryYear === '' ? startYear : otherValues.expiryYear.substring(2, otherValues.expiryYear.length),
      lineOfBusiness: otherValues.lineOfBusiness === '' ? '<lob>' : otherValues.lineOfBusiness,
    };

    if (fileFormat === 'client') {
      switch (attribute) {
        case 'valuedDate':
          const date = value.split('-');
          clientFileNameObj[attribute] = `${date[1]}.${date[2]}.${date[0].substring(2)}`;
          break;
        case 'effectiveYear':
        case 'expiryYear':
          clientFileNameObj[attribute] = value.substring(2, value.length);
          break;
        case 'subAccountName':
        case 'accountName':
        case 'carrierName':
        case 'lineOfBusiness':
          clientFileNameObj[attribute] = capitaliseFirstLetter(value);
          break;
      }
      // Account name-effective year(yy)-expiration year(yy)-LOB-loss runs- carrier-VD-mm.dd.yy format
      const clientFileName = `${selectedRadio.id === 'expiration' ? clientFileNameObj['accountName'] : clientFileNameObj['subAccountName']}-${clientFileNameObj['effectiveYear']}-${clientFileNameObj['expiryYear']}-${clientFileNameObj['lineOfBusiness']}-loss runs-${clientFileNameObj['carrierName']}-VD-${clientFileNameObj['valuedDate']}`;
      return clientFileName;
    } else {
      switch (attribute) {
        case 'valuedDate':
          const date = value.split('-');
          fileNameObj[attribute] = `${date[0]+date[1]}`;
          break;
        case 'effectiveYear':
        case 'expiryYear':
          fileNameObj[attribute] = value;
          break;
        case 'accountName':
        case 'lineOfBusiness':
        case 'subAccountName':
        case 'carrierName':
          fileNameObj[attribute] = toNormalizedLowerCase(value);
          break;
      }
      // yyyymm-client-account-carrier-lob-fiveyear-fivened-subaccount.pdf
      const fileName = `${fileNameObj['valuedDate']}-${selectedClientName.value}-${fileNameObj['accountName']}-${fileNameObj['carrierName']}-${fileNameObj['lineOfBusiness']}-${fileNameObj['effectiveYear']}-${fileNameObj['expiryYear']}-${selectedRadio.id === 'expiration' ? fileNameObj['accountName'] : fileNameObj['subAccountName']}`;
      return fileName;
    }
  };

  /* set lob and carrier for all selected files */
  const setDropdownValuesForSelectedFiles = (file: typeInMemoryFileData, fileDataList: typeInMemoryFileData[], attribute: 'lineOfBusiness' | 'carrierName' | 'valuedDate' | 'accountName' | 'subAccountName' | 'effectiveYear' | 'expiryYear', value: any) => {
    if (file.checked) {
      return fileDataList.map((file) => {
        return file.checked ? {
          ...file,
          [attribute]: value,
          subAccountName: (selectedRadio.id === 'expiration' && attribute === 'accountName') || (selectedRadio.id === 'dashboard' && attribute === 'subAccountName') ? value : file.subAccountName,
          mangoFileName: constructFileName(
              attribute, value,
              'mango',
              file,
          ),
          clientFileName: constructFileName(
              attribute, value,
              'client',
              file,
          ),
        } : {...file};
      });
    }
    return fileDataList;
  };

  /* Set dropdown data
   */
  const setData = (
      option: any, id: number, attribute: typeInMemoryFileDataAttr,
  ) => {
    const index = fileData.findIndex((data) => {
      return data.id === id;
    });
    let copyFileData = _.cloneDeep(fileData);
    if (index > -1) {
      if (attribute !== 'checked') {
        copyFileData = setDropdownValuesForSelectedFiles(
            copyFileData[index], copyFileData, attribute, option.value);
      }
      copyFileData[index] = {
        ...copyFileData[index],
        [attribute]: option.value,
        subAccountName: (selectedRadio.id === 'expiration' && attribute === 'accountName') || (selectedRadio.id === 'dashboard' && attribute === 'subAccountName') ? option.value : copyFileData[index].subAccountName,
        mangoFileName: attribute !== 'checked' ? constructFileName(
            attribute, option.value,
            'mango',
            fileData[index],
        ) : copyFileData[index].mangoFileName,
        clientFileName: attribute !== 'checked' ? constructFileName(
            attribute, option.value,
            'client',
            fileData[index],
        ) : copyFileData[index].clientFileName,
        error: {
          ...copyFileData[index].error,
          [attribute]: option.value === '' ? true : false,
        },
      };
      if (attribute === 'checked') {
        setSelectedCount((prevState) =>
        copyFileData[index].checked ? prevState + 1 : prevState-1);
      }
    }
    setFileData(copyFileData);
  };

  /* handle on click event for pdf link */
  const onClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const iframe = document.createElement('iframe');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('src', e.currentTarget.href);
    const w = window.open('');
    w?.document.write(iframe.outerHTML);
  };

  /* set client name */
  const setClientName = (option: any) => {
    setSelectedClientName(option);
    setClientError(option.value === '' ? true : false);
    const filteredData = entities.filter((entity) => {
      return entity.partitionKey === option.value;
    });
    setAccountList(createList(filteredData, 'accountName'));
  };

  function getUniqueFileName(fileNameList: typeInMemoryFileData[]) {
    const nameCount: {[key: string]: number} = {};

    fileNameList.forEach((file) => {
      if (nameCount[file.mangoFileName] === undefined) {
        nameCount[file.mangoFileName] = 0;
      } else {
        nameCount[file.mangoFileName] +=1;
        file.mangoFileName = `${file.mangoFileName}-${nameCount[file.mangoFileName]}`;
      }
    });

    return fileNameList;
  }

  /* validate and upload files into storage blob conatiner */
  const rename = () => {
    const validatedFileData : typeInMemoryFileData[] = [
    ];
    let isValid = true;
    [
      ..._.cloneDeep(fileData),
    ].forEach((file) => {
      if (file.accountName === '') {
        file.error.accountName = true;
        isValid = false;
      }
      if (file.carrierName === '') {
        file.error.carrierName = true;
        isValid = false;
      }
      if (file.lineOfBusiness === '') {
        file.error.lineOfBusiness = true;
        isValid = false;
      }
      if (file.subAccountName === '') {
        file.error.subAccountName = true;
        isValid = false;
      }
      if (file.effectiveYear === '') {
        file.error.effectiveYear = true;
        isValid = false;
      }
      if (file.expiryYear === '') {
        file.error.expiryYear = true;
        isValid = false;
      }
      if (file.valuedDate === '') {
        file.error.valuedDate = true;
        isValid = false;
      }
      validatedFileData.push(file);
    });

    if (selectedClientName.value === '') {
      setClientError(true);
      isValid = false;
    }
    setFileData(validatedFileData);

    if (isValid) {
      setLoading(true);
      const formData = new FormData();
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${accessToken}`);
      const uniqueFileData = getUniqueFileName(fileData);
      uniqueFileData.forEach((file) => {
        [
          {file: file.mangoFileName, folder: ROOT_FOLDER},
          {file: file.clientFileName, folder: ROOT_FOLDER_CLIENT},
        ].forEach((data) => {
          const fileFormat = getFileFormat(file.file.type);
          const filePath = `${currentDate.toISOString().split('T')[0]}/${file.accountName}/${data.folder}/${data.file}.${fileFormat}`;
          formData.append('files', file.file, filePath);
        });
      });
      fetch(`${AZURE_API_ENDPOINT}upload-blob`, {method: 'PUT', body: formData, headers: headers}).then((response) => {
        if (response.status === 201) {
          return response.blob();
        }
        throw new Error(response.statusText);
      }).then((result) => {
        saveAs(result, `${ZIP_DOWNLOAD_FILE_NAME_PREFIX}_${new Date().toLocaleDateString()}`, {autoBom: true});
        setLoading(false);
      }).catch((error: any) => {
        toast.error(JSON.stringify(error.toString()));
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (completedFilesCount === Math.ceil(fileData.length/2) &&
     completedFilesCount > 0) {
      toast.success('All files renamed and uploaded into storage container successfully!');
    }
  }, [
    completedFilesCount, fileData.length,
  ]);

  const clearAll = () => {
    setFileData([
    ]);
    setCompletedFilesCount(0);
    setLastFileId(0);
    setClientError(false);
    setShowDelete(false);
    setSelectedCount(0);
  };

  const setRadioValue = (
      e: React.ChangeEvent<HTMLInputElement>, id: string,
  ) => {
    setSelectedRadio({id: id, value: e.currentTarget.checked});
    setFileData(fileData.map((file) => {
      return {
        ...file,
        mangoFileName: '',
        clientFileName: '',
        lineOfBusiness: '',
        accountName: '',
        subAccountName: '',
        valuedDate: '',
        carrierName: '',
        effectiveYear: endYear.toString(),
        exipryYear: startYear.toString(),
        checked: false,
      };
    }));
    setSelectAllFiles(false);
    setSelectedCount(0);
  };

  useEffect(() => {
    if (maxUploadError !== '') {
      toast.error(maxUploadError);
    }
  }, [
    maxUploadError,
  ]);

  useEffect(() => {
    setShowDelete(selectedCount ? true : false);
    setSelectAllFiles((prevState) => selectedCount ? prevState : false);
  }, [
    selectedCount,
  ]);

  const handleSelectAllFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAllFiles(e.target.checked);
    setShowDelete(e.target.checked);
    setSelectedCount(e.target.checked ? fileData.length : 0);
    setFileData(fileData.map((file) => {
      return {
        ...file,
        checked: e.target.checked,
      };
    }));
  };

  const handleDelete = () => {
    setFileData(fileData.filter((file) => {
      return !file.checked;
    }));
    setShowDelete(false);
    setSelectedCount(0);
  };

  const renderFileIcon = (fileType: string) => {
    const fileFormat = getFileFormat(fileType);
    switch (fileFormat) {
      case 'pdf':
        return <PdfFileIcon width='28' height='28' className='me-2'/>;
      case 'xlsx':
      case 'xls':
        return <XlsxFileIcon width='28' height='28' className='me-2'/>;
      case 'csv':
        return <CsvFileIcon width='28' height='28' className='me-2'/>;
      default:
        return <PdfFileIcon width='28' height='28' className='me-2'/>;
    }
  };

  return (
    <div className='px-4 py-3 my-2 text-center'>
      <div className='container-fluid'>
        <div className='d-flex align-items-center gap-2 mb-3'>
          <Select
            options={clientList}
            value={selectedClientName}
            placeholder={'Select Client'}
            isSearchable
            onChange={(option) => setClientName(option)}
            className={`w-25 text-start ${clientError ? 'error-border' : ''}`}/>
          <RadioButton
            values={[
              ...RADIO_BUTTON_VALUES,
            ]}
            checked={selectedRadio}
            onChange={(e, id) => setRadioValue(e, id)}/>
          <span className='ms-auto'>Total files: {fileData.length}</span>
        </div>
        <div className='w-100 mb-4'>
          <input className='form-control' type='file' id='formFile' multiple onChange={(e) => getFiles(e)} disabled={selectedClientName.value === ''} accept={`${SUPPORTED_FILE_FORAMTS.join(',')}`}></input>
          <div className='d-flex align-items-center'>
            <span className={`text-start d-block text-gz-grey me-2`}>
              {selectedClientName.value === '' ? '(Select a client to choose files)' : `${HELP_TEXT}`}
            </span>
            <Tooltip text={<p>
              Upload files with the following naming convention to auto-fill.
              <br/><br/>
              <code>
                {`Account Name-EffectiveYear(yy)-ExpiryYear(yy)-LOB-loss runs-Carrier Name-VD-mm.dd.yy`}<br/><br/>
              </code>
              Eg: Upstate Services-14-16-CGLI-loss runs-Lloyds-VD-02.07.24</p>}>
              <InfoIcon width='14' height='14' fill='#cccccc'/>
            </Tooltip>
          </div>
        </div>
        <div className='d-flex align-items-center gap-2 file-header'>
          <Checkbox
            checked={{id: 'allfiles', value: selectAllFiles}} onChange={(e) => handleSelectAllFiles(e)}/>
          <span className='text-ellipsis-w40 w-25 text-start d-flex align-items-center'>
            {showDelete ?
              <>
                <DeleteIcon width='20px' height='20px' className='me-1 cursor-pointer' onClick={handleDelete}/>
                <span>{`${selectedCount} File(s) selected`}</span>
              </> :
            <>File Name</>}
          </span>
          <div className='d-flex align-items-center gap-2 w-75'>
            <span className='w-20'>Account Name</span>
            <span className='w-20'>Carrier Name</span>
            <span className='w-15'>LOB</span>
            <span className='w-20'>Valued Date</span>
            <span className='w-25'>Policy Term</span>
            <span className='w-20'>Sub Account Name</span>
            {/* <span className='w-25'>Optional Text</span> */}
          </div>
        </div>
        {fileData.length > 0 ?
        <div className='content-container gz-scroll'>{
          fileData.map((file) => {
            return (
              <div className='d-flex align-items-center gap-2 file-item' key={file.id}>
                <Checkbox
                  checked={{id: file.id.toString(), value: file.checked}}
                  onChange={(e) => setData({value: e.target.checked, lable: ''}, file.id, 'checked')}/>
                <div className='w-25 text-start d-flex align-items-center'>
                  {renderFileIcon(file.file.type)}
                  <a href={file.fileUrl}
                    onClick={(e) => onClickLink(e)}
                    title={file.originalFileName}
                    className='w-90 text-break'
                  >
                    {file.originalFileName}
                  </a>
                </div>
                <div className='d-flex align-items-center gap-2 w-75'>
                  <Select
                    options={accountList}
                    value={{value: file.accountName, label: file.accountName}}
                    placeholder={'Select Account'}
                    isSearchable
                    onChange={(option) => setData(option, file.id, 'accountName')}
                    className={`w-25 text-start ${file.error.accountName ? 'error-border' : ''}`}/>
                  <Select
                    options={carrierList}
                    value={{value: file.carrierName,
                      label: file.carrierName}}
                    placeholder={'Select Carrier'}
                    isSearchable
                    onChange={(option) => setData(option, file.id, 'carrierName')}
                    className={`w-25 text-start ${file.error.carrierName ? 'error-border': ''}`}/>
                  <Select
                    options={lobList}
                    value={{value: file.lineOfBusiness,
                      label: file.lineOfBusiness}}
                    placeholder={'Select LOB'}
                    isSearchable
                    onChange={(option) => setData(option, file.id, 'lineOfBusiness')}
                    className={`w-15 text-start ${file.error.lineOfBusiness ? 'error-border': ''}`}/>
                  <input type={'date'} onChange={(e) => setData({value: e.target.value, label: e.target.value}, file.id, 'valuedDate')} className={`w-20 calendar ${file.error.valuedDate ? 'error-border': ''}`} value={file.valuedDate}
                    id={`calendar-${file.id}`}></input>
                  <Select
                    options={policyYearList}
                    value={{value: file.effectiveYear,
                      label: file.effectiveYear}}
                    placeholder={'Select Start'}
                    isSearchable
                    onChange={(option) => setData(option, file.id, 'effectiveYear')}
                    className={`w-15 text-start ${file.error.effectiveYear ? 'error-border': ''}`}/>
                  <Select
                    options={getPolicyYear()}
                    value={{value: file.expiryYear,
                      label: file.expiryYear}}
                    placeholder={'Select End'}
                    isSearchable
                    onChange={(option) => setData(option, file.id, 'expiryYear')}
                    className={`w-15 text-start ${file.error.expiryYear ? 'error-border': ''}`}/>
                  <Select
                    options={subAccountList}
                    value={
                      {value: file.subAccountName,
                        label: file.subAccountName}}
                    placeholder={'Select Sub Account'}
                    isSearchable
                    isDisabled={selectedRadio.id === 'expiration'}
                    onChange={(option) => setData(option, file.id, 'subAccountName')}
                    className={`w-25 text-start ${file.error.subAccountName ? 'error-border': ''}`}/>
                  {/* <input type='text' className='calendar w-25'></input> */}
                </div>
              </div>
            );
          })}
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <button className='gz-btn-secondary me-2 ms-auto' onClick={clearAll}>
            Clear all
          </button>
          <button
            className={`gz-btn-primary-violet me-2 ${loading ? 'loading' : ''}`}
            onClick={() => {
              if (!loading) rename();
            }}>
            {completedFilesCount > 0 ?
            <span>{Math.ceil(
                completedFilesCount/2)} of {fileData.length} completed</span> :
            <>rename {loading && (<span className='inline-spinner'></span>)}</>}
          </button>
        </div>
        </div> :
        <>
          <GirlYogaIllustration width={280} height={280}/>
          <span className='d-block'>Yoga is the ultimate practice!</span>
        </>
        }
      </div>
    </div>
  );
}

export default Renamer;

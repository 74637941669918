import {AZURE_AUTHORITY_SUBDOMAIN, AZURE_GRAPH_ME_API, AZURE_REDIRECT_URI} from './constants/protectedResources';
import {LogLevel} from '@azure/msal-browser';
import {getEnvironmentVariable} from './helper';

export const msalConfig = {
  auth: {
    clientId: getEnvironmentVariable('REACT_APP_AZURE_CLIENT_ID'),
    authority: AZURE_AUTHORITY_SUBDOMAIN+getEnvironmentVariable('REACT_APP_AZURE_TENANT_ID'),
    redirectUri: AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: any, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [
    getEnvironmentVariable('REACT_APP_AZURE_LOGIN_SCOPE'),
  ],
};

export const graphConfig = {
  graphMeEndpoint: AZURE_GRAPH_ME_API,
};

function levenshtein(a: string, b: string) {
  const matrix = [
  ];
  let i;
  let j;

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  for (i = 0; i <= b.length; i++) {
    matrix[i] = [
      i,
    ];
  }

  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i-1) === a.charAt(j-1)) {
        matrix[i][j] = matrix[i-1][j-1];
      } else {
        matrix[i][j] = Math.min(
            matrix[i-1][j-1] + 1,
            matrix[i][j-1] + 1,
            matrix[i-1][j] + 1,
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function findSimilarWords(
    wordList: string[], inputWord: string, maxDistance: number = 10,
): {word: string, distance: number}[] {
  const similarWords = wordList.filter((word) =>
    levenshtein(inputWord.toLowerCase(), word.toLowerCase()) <= maxDistance);

  return similarWords.map((word) => {
    return {
      'word': word,
      'distance': levenshtein(inputWord, word),
    };
  }).sort((a, b) => a.distance - b.distance);
}

export {
  levenshtein,
  findSimilarWords,
};

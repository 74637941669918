import {AZURE_POST_LOGOUT_REDIRECT_URI} from '../constants/protectedResources';
import React from 'react';
import {useMsal} from '@azure/msal-react';

export const SignOutButton = () => {
  const {instance} = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: AZURE_POST_LOGOUT_REDIRECT_URI,
    });
  };

  return (
    <button className='gz-btn-primary-violet' onClick={handleLogout}>
      Signout
    </button>
  );
};

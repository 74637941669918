function getEnvironmentVariable(name: string): string {
  const value = process.env[name.toUpperCase()];
  return value ? value : '';
}

function capitaliseFirstLetter(value: string) {
  return value.substring(0, 1).toUpperCase()+value.substring(1, value.length);
}

function toNormalizedLowerCase(value: string) {
  return value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

function getResourceURI(resource: 'storageBlob' | 'storageTable') {
  const storageAccountName = getEnvironmentVariable('REACT_APP_AZURE_STORAGE_ACCOUNT');
  switch (resource) {
    case 'storageBlob':
      return `https://${storageAccountName}.blob.core.windows.net`;
    case 'storageTable':
      return `https://${storageAccountName}.table.core.windows.net/`;
  }
}

function getFileFormat(fileType: string) {
  switch (fileType) {
    case 'application/pdf':
      return 'pdf';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'text/csv':
      return 'csv';
    case 'application/vnd.ms-excel':
      return 'xls';
  }
}


function checkFileNameMatchesPattern(fileName: string) {
  const pattern = '^([a-zA-Z0-9 ]+)-(\\d{2})-(\\d{2})-([a-zA-Z]+)-loss runs-([a-zA-Z0-9 ]+)-VD-(\\d{2})\\.(\\d{2})\\.(\\d{2})\\.([a-zA-Z]+)$';
  const match = fileName.match(pattern);

  if (match) {
    const [
      _, accountName, startYear, endYear, lob,
      carrierName, month, day, year,
    ] = match;
    return {
      _, accountName, startYear, endYear, lob,
      carrierName, month, day, year,
    };
  } else {
    return null;
  }
}

export {
  getEnvironmentVariable,
  capitaliseFirstLetter,
  getResourceURI,
  getFileFormat,
  toNormalizedLowerCase,
  checkFileNameMatchesPattern,
};

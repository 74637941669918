import {ReactComponent as GoZealLogo} from '../images/gozeal-logo.svg';
import React from 'react';
import {SignInButton} from './SignInButton';
import {SignOutButton} from './SignOutButton';
import {useIsAuthenticated} from '@azure/msal-react';

const Header = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className='px-4 py-3 my-2 text-center'>
      <nav id='navbar' className='navbar navbar-expand-lg'>
        <div className='container-fluid navbar-container'>
          <GoZealLogo className='d-block mb-4'/>
          <div className='collapse navbar-collapse justify-content-end'>
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;

